<template>
  <div id="custom-app-bar">
    <!-- top bar -->
    <div class="page-size d-flex company-wrapper justify-start">
      <div class="company-logo" v-if="dealerJSON">
        <router-link to="/" target="_self">
          <img alt="Logo" class="mr-3" :src="httpProtocol + dealerJSON.config.logo" :height="dealerLogoSize"/>
        </router-link>
      </div>
      <div class="company-name" v-if="dealerJSON && currentDealer">
        {{dealerJSON.config.app_bar_logo_text}}
      </div>
      <!-- Main menu -->
      <MainMenuDesk :pagesArray="topPages" v-if="dealerJSON && topPages.length > 0" />
      <div class="MainMenuMobileButton" v-if="topPages.length > 0">
        <v-icon aria-hidden="true" class="ml-2 menuAccordion" color="#ffffff" @click="$emit('MainMenuClick');">mdi-menu</v-icon>
      </div>
    </div>
    <!-- top bar -->
  </div>
</template>

<script>
import MainMenuDesk from '@/components/mainMenu/MainMenuDesk.vue';
import { mapState } from 'vuex'; // introducing mapState for store
export default {
  name: 'Appbar',
  components: {
    MainMenuDesk
  },
  data: () => ({
    dealerLogoSize: '40',
    httpProtocol: window.location.protocol + '//',
    currentDealer: null
  }),
  computed: {
    ...mapState({
      dealerJSON: state => state.dealerJSON, // get data from store
      dealerConfigData: state => state.dealerConfigData // get data from store
    }),
    topPages() {
      // filter pages array on menu_placement == 'bottom'
      var newArray = this.dealerJSON.pages.filter(item => {return item.menu_placement == 'top'});
      return newArray; // return the filtered Array for use in ul footer page url's
    }
  },
  created() {
    this.dealerLogoSize = this.dealerConfigData.dealer_logo.app_bar_height;
  }
};
</script>

<style lang="scss">
  #custom-app-bar {
    position: fixed;
    top:0;
    left:0;
    z-index: 9;
    width: 100%;
    height: 64px;
    background-color: black;
    display: flex;
    align-items: center;
    border-radius: 0;
    padding: 4px 16px;
    .company-logo,
    .company-name {
      align-self:center;
    }
    .company-logo {
      &:hover {
        cursor: pointer;
      }
    }
    .company-name {
      color:white;
    }
  }
  .app-bar-padding-top {
    padding-top:64px;
  }
  .MainMenuMobileButton {
    margin-left: auto;
    .menuAccordion {
      height: 100%;
    }
  }
  // @media only screen and (max-width:599px) {
  @media only screen and (max-width:767px) {
    .navDeskMenu {
      display: none;
    }
    #custom-app-bar {
      height: 56px !important;
    }
    .app-bar-padding-top {
      padding-top:56px !important;
    }
  }
  @media only screen and (min-width:768px) {
    .MainMenuMobileButton {
      display: none;
    }
  }
</style>