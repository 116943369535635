<template>
  <ul class="navDeskMenu" v-if="pagesArray.length > 0">
    <li v-for="(page, idx) in pagesArray" :key="idx"><router-link class="pl-3 pr-3" :to="page.url_alias" target="_self">{{ page.menu_name }}</router-link></li>
  </ul>
</template>

<script>
export default {
  name: 'MainMenuDesk',
  props: {
    pagesArray: Array,
  }
};
</script>

<style lang="scss">
ul.navDeskMenu {
  display: flex;
  margin-left: auto;
  li {
    list-style: none;
    a {
      color:#ffffff;
      text-decoration: none;
      vertical-align: middle;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
@media only screen and (max-width:600px) {

}
// @media only screen and (max-width:599px) {
@media only screen and (max-width:767px) {
  ul.navDeskMenu {
    display: none;
  }
}
</style>